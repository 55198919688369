import React from "react"
import Container from "../../components/container"
import CustomHeader from "../../components/customHeader"
import SideBarView from "../../components/SideBarView"
import Seo from "../../components/Seo"
import { Typography, Grid } from "@mui/material"
import { CodeBlockView } from "../../components/HelperComponents"

const programmingRoutineView = (data) => {
  return (
    <div style={{ marginTop: "10px", marginBottom: "15px" }}>
      <Typography variant={`h6`}>{data.title}</Typography>
      <CodeBlockView code={data.code && (data.code).trim()} language={data.language}/>
      {data.description}
    </div>
  )
}


const JavascriptTimestampConversions = () => {
  return (
    <Container>
      <CustomHeader title={`Javascript Timestamp Conversions`}/>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={9} xl={9}>
          <Typography variant={`p`}>
            Working with timestamps is an essential part of many applications, including tracking user activity and
            scheduling events. In JavaScript, a timestamp is a numeric value that represents the number of milliseconds
            that have passed since <b>January 1, 1970, 00:00:00 UTC</b>.
            <Typography variant={`h2`} sx={{ marginTop: `30px` }}>
              Converting Timestamps to Dates
            </Typography>
            To convert a timestamp to a human-readable date and time format, JavaScript provides the <b>new Date()</b>
            constructor and two methods:
            <ul>
              <li><b>Date.toLocaleString():</b> returns a string representation of a date and time using the system’s local
                time zone
              </li>
              <li><b>Date.toUTCString():</b> returns a string representation of a date and time in UTC</li>
            </ul>
            Example:
            {programmingRoutineView({
              language: `javascript`,
              code: `const timestamp = 1647022561867;
const date = new Date(timestamp);
const localTimeString = date.toLocaleString();
const utcTimeString = date.toUTCString();

console.log(localTimeString); // "3/11/2023, 4:36:01 PM"
console.log(utcTimeString); // "Fri, 11 Mar 2023 16:36:01 GMT"`
            })}
            <Typography variant={`h2`} sx={{ marginTop: `30px` }}>
              Converting Dates to Timestamps
            </Typography>
            To convert a date and time value to a timestamp, JavaScript provides three methods:
            <ul>
              <li><b>Date.getTime():</b> returns the timestamp value for a Date object</li>
              <li><b>Date.parse():</b> parses a date string and returns a timestamp value</li>
              <li><b>Date.UTC():</b> returns a timestamp value for a specified date and time in UTC</li>
            </ul>
            {programmingRoutineView({
              language: `javascript`,
              code: `const dateString = "March 11, 2023 4:36:01 PM";
const timestamp1 = Date.parse(dateString);
const date = new Date(dateString);
const timestamp2 = date.getTime();
const timestamp3 = Date.UTC(2023, 2, 11, 16, 36, 1);

console.log(timestamp1); // 1647022561000
console.log(timestamp2); // 1647022561000
console.log(timestamp3); // 1647022561000`
            })}
          </Typography>
          <Typography variant={`p`}>
            JavaScript provides several methods for converting timestamps between different formats. Here are the most
            common types of timestamp conversions in JavaScript with examples:
          </Typography>
          <Typography variant={`h2`} sx={{ marginTop: `30px` }}>
            Unix Timestamp to JavaScript Date Object:
          </Typography>
          <Typography variant={`p`}>
            Unix timestamps are the number of seconds that have elapsed since January 1, 1970. To convert a Unix
            timestamp to a JavaScript Date object, you can use the <b>new Date()</b> constructor and pass the timestamp as an
            argument. Here's an example:
          </Typography>
          {programmingRoutineView({
            language: `javascript`,
            code: `const unixTimestamp = 1615426140;
const dateObject = new Date(unixTimestamp * 1000);
console.log(dateObject);
// Output: Fri Mar 10 2021 23:29:00 GMT+0530 (India Standard Time)`
          })}
          <Typography variant={`h2`} sx={{ marginTop: `30px` }}>
            JavaScript Date Object to Unix Timestamp:
          </Typography>
          <Typography variant={`p`}>
            To convert a JavaScript Date object to a Unix timestamp, you can use the <b>getTime()</b> method to get the number
            of milliseconds since January 1, 1970, and then divide it by 1000 to get the number of seconds. Here's an
            example:
          </Typography>
          {programmingRoutineView({
            language: `javascript`,
            code: `const dateObject = new Date();
const unixTimestamp = Math.floor(dateObject.getTime() / 1000);
console.log(unixTimestamp);
// Output: 1647015372`
          })}
          <Typography variant={`h2`} sx={{ marginTop: `30px` }}>
            JavaScript Date Object to ISO String:
          </Typography>
          <Typography variant={`p`}>
            ISO string is a standard format for representing dates and times in a readable format. To convert a
            JavaScript Date object to an ISO string, you can use the <b>toISOString()</b> method. Here's an example:
          </Typography>
          {programmingRoutineView({
            language: `javascript`,
            code: `const dateObject = new Date();
const isoString = dateObject.toISOString();
console.log(isoString);
// Output: 2022-03-10T18:18:53.689Z`
          })}
          <Typography variant={`h2`} sx={{ marginTop: `30px` }}>
            ISO String to JavaScript Date Object:
          </Typography>
          <Typography variant={`p`}>
            To convert an ISO string to a JavaScript Date object, you can use the <b>Date.parse()</b> method and pass the
            string as an argument. Here's an example:
          </Typography>
          {programmingRoutineView({
            language: `javascript`,
            code: `const isoString = '2022-03-10T18:18:53.689Z';
const dateObject = new Date(Date.parse(isoString));
console.log(dateObject);
// Output: Fri Mar 10 2022 23:48:53 GMT+0530 (India Standard Time)`
          })}
          <Typography variant={`h2`} sx={{ marginTop: `30px` }}>
            JavaScript Date Object to Local String:
          </Typography>
          <Typography variant={`p`}>
            Local string is a string representation of a date and time in the browser's local time zone. To convert a
            JavaScript Date object to a local string, you can use the <b>toLocaleString()</b> method. Here's an example:
          </Typography>
          {programmingRoutineView({
            language: `javascript`,
            code: `const dateObject = new Date();
const localString = dateObject.toLocaleString();
console.log(localString);
// Output: 3/11/2023, 12:26:53 AM`
          })}
          <Typography variant={`p`}>
            These are the most common types of timestamp conversions in JavaScript. It's important to understand these
            conversions to work with dates and times in JavaScript effectively.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={3} xl={3}>
          <SideBarView/>
        </Grid>
      </Grid>
    </Container>
  )
}

export default JavascriptTimestampConversions

export const Head = () => {
  return (
    <Seo
      title="Ultimate Guide to Timestamp Conversions in JavaScript: Best Practices and Tips"
      canonicalUrl={"https://www.epochconverter.io/blogs/javascript-timestamp-conversions"}
    />
  )
}
